<template>
    <div id="side">
        <!-- @mouseenter="enter" -->
        <!-- <button @mouseenter="enter">444</button> -->

        <!-- <div class="side1" v-on:mouseenter="enter()"  v-on:mouseleave ="out()" ></div> -->
        <!-- <div class="dangban" v-on:mouseenter="enter1()"  v-on:mouseleave ="out1()"></div> -->
        <!-- v-clickoutside="handleClose"   v-on:mouseenter="enter1()"  v-on:mouseleave ="out1()" -->
        <div class="box" style="height:100%;"  >
            <div class="side"  v-on:mouseenter="enter1()" v-on:mouseleave ="out()" v-if="tabShow == true">
                <div class="icon1 box around center hand" :class="home1An==true?'icon1':'icon11'" @click="getPage('template')"  v-on:mouseenter="enter()">
                    <span class="iconImage1"></span>
                </div>
               <div class="icon2 box around center hand" :class="{'icon20': home2An== 1, 'icon21': home2An== 2, 'icon22': home2An == 3}"  @click="getPage('model')">
                    <span class="iconImage2"></span>
                </div>
                 <div class="icon3 box around center hand" :class="{'icon30': home3An== 1, 'icon31': home3An== 2, 'icon32': home3An == 3}"  @click="getPage('videoShow')">
                    <span class="iconImage3"></span>
                </div>
                 <!-- <div class="icon4 box around center hand" :class="{'icon40': home4An== 1, 'icon41': home4An== 2, 'icon42': home4An == 3}"  @click="getPage('map')">
                    <span class="iconImage4"></span>
                </div> -->
                 <div class="icon5 box around center hand" :class="{'icon50': home5An== 1, 'icon51': home5An== 2, 'icon52': home5An == 3}"  @click="getPage('xiazai')">
                    <span class="iconImage5"></span>
                </div>
                <div :class="bg==true?'side1':'side11'"></div>
            </div>
            <!-- 场景选择 -->
            <div class="box"  v-if="show == true" >
                <div class="boxshow  box"  v-on:mouseenter="enter2()" :class="mouseenter1==true?'change2':'change3'">
                    <div class="main box box_column" v-if='show1 ==true'>
                        <p class="close hand" @click="close">
                            <img src="../assets/images/close.png" alt="">
                        </p>
                        <div class="div1">
                            <p class="fs18 blod  btnBox" >场景分类</p>
                            <ul class="ul box box_column ">
                                <li class="">
                                    <span :class="{'active':line1==1}"  class="white hand" style="margin-right:62px" @click="go(2,1)">三维场景</span>
                                    <span :class="{'active':line1==2}" class="white hand"  @click="go(3,2)">地图场景</span>
                                </li>
                            </ul>
                        </div>
                        <!-- 颜色区分 -->
                        <div class="div1">
                            <p class="fs18 blod btnBox" >环境分类</p>
                            <div>
                                <span v-for= "(item, index) in colorArr" :key='index' :class="{'active':line2==index}" style="margin-right:30px" class="white hand sp textC"  @click="goColor(item.id, index)">{{item.name}}</span>
                            </div>
                        </div>
                        <!-- 风格区分 -->
                         <div class="div1">
                            <p class="fs18 blod btnBox">风格分类</p>
                            <div>
                                <span v-for= "(item, index) in styleArr" :key='index'  :class="{'active':line3==index}"  style="margin-right:30px" class="white hand sp1 textC" @click="goStyle(item.id,index)">{{item.name}}</span>
                            </div>
                        </div>
                        <!-- 空间区分 -->
                         <div class="div1">
                            <p class="fs18 blod btnBox" >空间分类</p>
                            <div>
                                <span v-for= "(item, index) in spaceArr" :key='index'  :class="{'active':line4==index}" style="margin-right:30px" class="white hand sp1 textC" @click="spaceSelecet(item.id,index)">{{item.name}}</span>
                            </div>
                        </div>
                       
                    </div>
                    <div v-else class="main mainW">
                        <p class="close hand" @click="close">
                             <img src="../assets/images/close.png" alt="">
                        </p>
                        <p class="btnBox blod"  style="margin-top:13%">视频展示</p>
                        <div>
                            <p class="sp2" v-for= "(item, index) in videoArr" :key='index'  >
                                <span   style="margin-right:30px" class="white hand" :class="{'active':line5==index}"  @click="vidwoSelecet(item.id,index)">{{item.name}}</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>



        </div>
                            
    </div>
</template>

<script>

const clickoutside = {
    // 初始化指令
    bind(el, binding, vnode) {
        function documentHandler(e) {
            // 这里判断点击的元素是否是本身，是本身，则返回
            if (el.contains(e.target)) {
                return false;
            }
            // 判断指令中是否绑定了函数
            if (binding.expression) {
                // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
                binding.value(e);
            }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = documentHandler;
        document.addEventListener('click', documentHandler);
    },
    unbind(el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
    },
};


// var timer;
var timer;
var timer1;
var timer2;
    export default {
        data() {
            return {
                colorArr:[
                    {'name':'深色','id':'1'}, {'name':'浅色','id':'2'}, {'name':'绿色','id':'3'}, {'name':'蓝色','id':'4'},
                    {'name':'黄绿','id':'5'}, {'name':'蓝橙','id':'6'}, {'name':'棕色','id':'7'}, {'name':'灰色','id':'8'},
                    {'name':'单色','id':'9'}, {'name':'黑色','id':'10'}
                ],
                styleArr:[
                    {'name':'青绿山水','id':'1'}, {'name':'中国风','id':'2'}, {'name':'写实','id':'3'}, 
                    {'name':'low-poly','id':'4'},{'name':'科技','id':'5'}, {'name':'扁平','id':'6'}, 
                    {'name':'建筑绘画','id':'7'}, {'name':'日式写意','id':'8'},{'name':'太空','id':'9'}
                ],
                spaceArr:[
                    {'name':'城市建筑','id':'1'}, {'name':'2.5D模型','id':'2'}, {'name':'2D/3D混合','id':'3'}, 
                    {'name':'3D模型','id':'4'},{'name':'2D模型','id':'5'}, {'name':'地形图','id':'6'}, 
                    {'name':'卫星图','id':'7'}, {'name':'着色','id':'8'}
                ],
                videoArr:[
                    {'name':'湖杭铁路智慧工地','id':'https://cloudansys.biggersun.com/DemoApp_01.mp4'},
                    {'name':'国家会议中心卸载施工监测平台','id':'https://cloudansys.biggersun.com/DemoApp_02.mp4'},
                    {'name':'都四线城市轨道智能管理系统','id':'https://cloudansys.biggersun.com/DemoApp_03.mp4'},
                    {'name':'中国国际丝路中心大厦顶升平台','id':'https://cloudansys.biggersun.com/DemoApp_04.mp4'},
                    {'name':'智慧管网实验方案','id':'https://cloudansys.biggersun.com/DemoApp_05.mp4'},
                    {'name':'北投防城港漏损控制系统','id':'https://cloudansys.biggersun.com/DemoApp_06.mp4'},
                ],

                home: true,
                show: false,
                menu: false,
                all: false,
                download:false,
                video: false,

                mouseenter: false,
                mouseenter1: true,

                show1: false,

                line1:'100',
                line2:'100',
                line3:'100',
                line4:'100',
                line5:'100',

                //动画设置
                home1An: true,
                home2An: 1,
                home3An: 1,
                home4An: 1,
                home5An: 1,
                bg: false,

                //主菜单
                tabShow: true,
                windowRef:true,
                
            }
        },
         directives: {clickoutside},
        watch:{
            show(n, o){
                if(n == true){
                     setTimeout(() => {
                        this.flag = false;
                    }, 5000);
                }
            }

        },
        created () {

        },
        mounted () {
                // timer1 = setTimeout(() => {
                //         console.log('这是开始的定时器')
                //         // this.mouseenter = true;    //关闭
                // }, 3000);
        },
        methods: {
            time(){
                 timer = setTimeout(() => {
                    this.home1An = true;
                    this.home2An = 3;
                    this.home3An = 3;
                    this.home4An = 3;
                    this.home5An = 3;
                    this.bg = false;
                }, 5000);
            },

            enter(){
                console.log('显示菜单');
                this.homebtn();  
            },
            out(){
                console.log('启动定时器');
                this.time();
            },

            //触摸停时
            enter1(){
                console.log('暂停定时器');
                 clearTimeout(timer); 
            },

               //点击菜单
            homebtn(){
                this.home1An = false;
                this.home2An = 2;
                this.home3An = 2;
                this.home4An = 2;
                this.home5An = 2;
                this.bg = true;


                // this.home1An = !this.home1An;

                // if(this.home2An ==2){
                //     this.home2An =3
                // }else{
                //     this.home2An =2
                // }
                
                // if(this.home3An ==2){
                //     this.home3An =3
                // }else{
                //     this.home3An =2
                // }

                // if(this.home4An ==2){
                //     this.home4An =3
                // }else{
                //     this.home4An =2
                // }

                // if(this.home5An ==2){
                //     this.home5An =3
                // }else{
                //     this.home5An =2
                // }

                // this.bg = !this.bg;

            },


            





            // 导航
            goClist(name){
                  setTimeout(timer1);
                  if(name=='home'){
                     console.log('点击菜单了')
                     this.home = true;
                     this.menu = false;
                     this.all = false;
                     this.download = false;
                     this.video = false;
                     this.show = false;
                     this.noActive();   //情况二级菜单选中
                     this.$router.push({
                          path:'/',
                     })
                 }

                //分类
                 if(name=='menu'){ 
                    //  console.log('点击菜单了')
                    clearTimeout(timer1)

                     this.mouseenter = false;  //显示弹框动画
                     this.home = false;
                     this.menu = true;
                     this.all = false;
                     this.download = false; 
                     this.video = false;

                     this.show = true;       //显示二级弹框
                     this.show1 = true;
                     this.mouseenter1 = false;  //显示弹框动画

                    console.log('mouseenter', this.mouseenter);
                 }
                 //缩略图
                 if(name=='all'){   
                     this.noActive();   //情况二级菜单选中
                     this.home = false;
                     this.menu = false;
                     this.all = true;
                     this.mouseenter1 = true;
                     this.download = false;
                     this.video = false;
                     this.$router.push({
                          path:'/picture',
                     })
                 }
                if( name=='download'){
                    this.noActive();   //情况二级菜单选中
                    this.home = false;
                    this.menu = false;
                    this.all = false;
                    this.download = true;
                    this.mouseenter1 = true;
                    this.home = false;
                    this.video = false;
                 }
                 if( name=='video'){
                    this.home = false;
                    this.menu = false;
                    this.all = false;
                    this.download = false;
                    this.home = false;

                    this.video = true;
                    this.show = true;       //显示二级弹框
                    this.mouseenter1 = false;  //显示弹框动画
                    this.show1 = false;    //视频内容
                 }

                 if( name == 'download'){
                    //  console.log('下载')
                    var url = 'https://cloudansys.biggersun.com/cloudansys_solution.pdf'; 
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    // link.target = '_black';
                    // link.setAttribute('download', name)   // 文件名
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)    // 下载完成移除元素

                //    var elemIF = document.createElement("iframe");
                //     elemIF.src = url;
                //     elemIF.style.display = "none";
                //     document.body.appendChild(elemIF);

                //     // a 
                //     var a = document.createElement('a');
                //     a.href = url;
                //     a.click();


         
                     
                 }
            },


            //跳转到3d 或者地图
            go(type,index){
                this.tabShow = true;
                if(index ==1){
                    this.line1 = 1;
                }
                if(index ==2){
                    this.line1 = 2;
                }
                this.line2 = 100;
                this.line3 = 100;
                this.line4 = 100;
                this.line5 = 100;

                var name;
                this.menu = false;
                this.mouseenter1= true;
                this.mouseenter = true;
                // this.out1();
                this.$router.push({
                    path:'/model',
                    query:{
                        typeName: type
                    }
                })
            },
    
 
            //选择深浅色
            goColor(val, index){
                this.tabShow = true;

                this.line2 = index;
                this.line1 = 100;
                this.line3 = 100;
                this.line4 = 100;
                this.line5 = 100;

                this.menu = false;
                this.mouseenter1= true;   // 板子
                this.mouseenter = true;   // 导航没有了
                // this.out1();
                this.$router.push({
                    path:'/model',
                    query:{
                        color: val,   //颜色值
                        typeName: '1'    //全部内容
                    }
                })

            },

            //选择风格
            goStyle(val,index){
                this.tabShow = true;

                this.line2 = 100;
                this.line1 = 100;
                this.line3 = index;
                this.line4 = 100;
                this.line5 = 100;

                this.menu = false;
                this.mouseenter1= true;
                this.mouseenter = true;
                // this.out1();
                this.$router.push({
                    path:'/model',
                    query:{
                        style: val,
                         typeName: '1'    //全部内容
                    }
                })
            },

            //选择质感
            spaceSelecet(val,index){
                this.tabShow = true;

                this.line2 = 100;
                this.line1 = 100;
                this.line3 = 100;
                this.line4 = index;
                this.line5 = 100;

                this.menu = false;
                this.mouseenter1= true;
                this.mouseenter = true;
                // this.out1();
                this.$router.push({
                    path:'/model',
                    query:{
                        space: val,
                        typeName: '1'    //全部内容
                    }
                })
            },

            // 视频选择
            vidwoSelecet(id, index){
                this.tabShow = true;

                this.line2 = 100;
                this.line1 = 100;
                this.line3 = 100;
                this.line4 = 100;
                this.line5 = index;

                this.video = false
                this.mouseenter1= true;
                this.mouseenter = true;

                this.$router.push({
                    path:'/video',
                    query:{
                        videoId: id,
                    }
                })

            },

            //情况二级菜单选中
            noActive(){
                this.line2 = 100;
                this.line1 = 100;
                this.line3 = 100;
                this.line4 = 100;
                this.line5 = 100;
            },


            handleClose(){
                console.log('点击空白处');
                console.log('line5',this.line5)
                if(this.line5 == 100){
                     this.video = false;   //清空视频选中样式
                }
                clearTimeout(timer1);
                this.menu = false;
                this.mouseenter1 = true;
                timer1 = setTimeout(() => {   //重新打开定时器 隐藏右侧滚动条
                    console.log('消失')
                    this.mouseenter = true;      //消失
                }, 5000);
            
                
            },


            //既然怒
            // enter1(){
            //     this.mouseenter = false;        //显示
            //     console.log(timer1)
            //     clearTimeout(timer1)
            //     this.mouseenter = false;
            //     console.log('进进进', this.mouseenter)
                
            // },

            out1(){
                console.log('出出出', this.mouseenter)
                if(this.mouseenter1 != false){
                    timer1 = setTimeout(() => {
                         this.mouseenter = true;  
                    }, 3000);
                }
                // console.log(timer1)
            },
            btn1(){
                this.show = true;
                 this.mouseenter1 = false;
            },
            enter2(){
                clearTimeout(timer1);
            },

            close(){
                this.tabShow = true;
                 this.mouseenter1= true;
                this.mouseenter = true;
            },
          
            getPage(name){
                this.show = false;
                this.tabShow = true;
                if(name == 'xiazai'){
                    var path = this.$route.path
                        if( path == "/template"){
                            return
                     }

                    var src  = this.$store.state.src;
                    console.log('src', src)

                    // var url = 'https://cloudansys.biggersun.com/cloudansys_solution.pdf'; 
                    // var  imgsrc = 'http://192.168.16.66:8081'+src;
                   var  imgsrc = 'http://portal.cloudansys.cn'+src;
                    console.log('imgsrc', imgsrc)
                    var name = '';
                    var image = new Image()
                    image.setAttribute('crossOrigin', 'anonymous')
                    image.onload = function() {
                        var canvas = document.createElement('canvas')
                        canvas.width = image.width
                        canvas.height = image.height
                        var context = canvas.getContext('2d')
                        context.drawImage(image, 0, 0, image.width, image.height)
                        var url = canvas.toDataURL('image/png')
                        var a = document.createElement('a')
                        var event = new MouseEvent('click')
                        a.download = name || 'photo'
                        a.href = url
                        a.dispatchEvent(event)
                    }
                    image.src = imgsrc
                                


                    // let link = document.createElement('a')
                    // link.style.display = 'none'
                    // link.href = url
                    // document.body.appendChild(link)
                    // link.click()
                    // document.body.removeChild(link)    // 下载完成移除元素
                    return;
                }

                if(name=='model'){ 
                    console.log('8787878')
                    this.$store.commit('changeState');
                    var parmas = this.$route.query;

                    // if(parmas.typeName == 0){
                    //     this.mouseenter1 = true;
                    //     this.tabShow = true;
                    //     this.windowRef = false;
                    // }
                   
                    this.windowRef = true;
                    this.tabShow = false;
                    //  console.log('点击菜单了')
                    clearTimeout(timer1)

                     this.mouseenter = false;  //显示弹框动画
                     this.home = false;
                     this.menu = true;
                     this.all = false;
                     this.download = false; 
                     this.video = false;

                     this.show = true;       //显示二级弹框
                     this.show1 = true;
                     this.mouseenter1 = false;  //显示弹框动画

                    // console.log('mouseenter', this.mouseenter);
                    
                    console.log('parmas',parmas)
                    if(parmas.typeName ==2 || parmas.typeName ==3){
                        console.log(777)
                         return
                    }

                   var arr = [];
                    for (let key in parmas){
                        console.log(key);
                        arr.push(key)
                        //add your statement to get key value
                    }
                
                    if(arr.length == 1){
                         this.$router.push({
                            path:'/'+name,
                            query:{
                                typeName: 1
                            }
                        })
                        return;
                    }

                    // console.log('parmas1',parmas)
                    // var b = (JSON.stringify(parmas) == "{}");
                    // if(b==true){
                    //     console.log('kong')
                    //     this.mouseenter1 = true; 
                    //     this.mouseenter = false; 
                    //     this.$router.push({
                    //         path:'/'+name,
                    //         query:{
                    //             typeName: 1
                    //         }
                    //     })
                    //     return;
                    // }

                    console.log('parmas',parmas.color)
                    console.log('parmas',parmas.style)
                    console.log('parmas',parmas.space)
                    console.log('parmas',parmas.typeName)

                
                  
                    if(parmas.color !='undefined' || parmas.style !='undefined' || parmas.space !='undefined'){
                        console.log(666)
                        return
                    }
                 }

                if( name=='videoShow'){
                    this.tabShow = false;

                    this.home = false;
                    this.menu = false;
                    this.all = false;
                    this.download = false;
                    this.home = false;

                    this.video = true;
                    this.show = true;       //显示二级弹框
                    this.mouseenter1 = false;  //显示弹框动画
                    this.show1 = false;    //视频内容
                    return;
                 }

                if(name == 'template'){
                    this.line1='100';
                    this.line2='100';
                    this.line3='100';
                    this.line4='100';
                    this.line5='100';

                    this.$router.push({
                        path:'/'+name,
                        query:{
                            typeName: 0
                         }
                    })
                    return;
                }


                this.$router.push({
                    path:'/'+name,
                    query:{
                        typeName: 1
                    }
                })
            },





        },
    }
</script>

<style lang="scss" scoped>
    #side{
        // width: 514px;
        height: 100%;
        // float: left;
        position: absolute;
        z-index: 999;
        // background-color: rgba($color: red, $alpha: 0.5);

    }
    //触摸块
    .side{
        width: 300px;
        height: 270px;
        position: absolute;
        z-index: 999;
        // border: 1px solid red;
    }


    .demo1{
        color: white;
    }




    // 进入   离开结束
    .v-enter,
    .v-leave-to{
        opacity: 0;
        transform: translateX(-110px);
    }

    //  .v-enter-to,
    // .v-leave{
    //     opacity: 0;
    //     transform: translateX(-90px);
    // }

    // 入场的时间段   出场的时间段
    .v-enter-active,
    .v-leave-active{
        transition: all 0.8s ease;   //
    }




    // 进入   离开结束
    .move-enter,
    .move-leave-to{
        opacity: 0;
        transform: translateX(-90px);
    }

    // 入场的时间段   出场的时间段
    .move-enter-active,
    .move-leave-active{
        transition: all 0.8s ease;   //
    }

    .btnBox{
        color: #40434A;
        width: 104px;
        height: 34px;
        background-color: #ffffff;
        border-radius: 5px;
        display: inline-block;
        text-align: center;
        line-height: 34px;
        margin-bottom: 8%;
        
    }


    .change{
        animation: myMou 0.5s;
        animation-fill-mode: forwards;
    }
    .change1{
        animation: myMou1 0.5s;
        animation-fill-mode: forwards;
    }
    @keyframes myMou {
        0% {
            position: absolute;
            left: 0;
        }
        100% {
            position: absolute;
            left: -200px;
        }
    }
    @keyframes myMou1 {
        0% { 
            position: absolute;
            left: -200px;
        }
        100% {
             position: absolute;
            left: 0;
        }
    }


    // 
    .change2{
        animation: myMou2 0.5s;
        animation-fill-mode: forwards;
    }
    .change3{
        animation: myMou3 0.5s;
        animation-fill-mode: forwards;
    }
    @keyframes myMou2 {
        0% {
            position: absolute;
            left: 0;
        }
        100% {
            position: absolute;
            left: -520px;
        }
    }
    @keyframes myMou3 {
        0% { 
            position: absolute;
            left: -520px;
        }
        100% {
            position: absolute;
            left: 0;
        }
    }
    //导航菜单
    .ul1{
       text-align: left;
    }
    .ul1 li{
           margin-bottom: 58px;
    }
    .ul1 img{
        width:30px;
    }

    //弹出二级菜单
    .boxshow{
         width:414px;
        height: 100%;
        // background-color: #234763;
        // background: rgba($color: #234763, $alpha: 0.7);
        background: rgb(11 27 39 / 85%);
        // padding-top: 61px;
        padding-left: 45px;
        text-align: left;
    
    }

    .main{
        // border: 1px solid red;
        height: 80%;
        position: absolute;
        top: 2%;
        // margin-top: 200px;
        // left: 10%;
        text-align: left;
        
    }
    
    .close{
        // width: 400px;
        margin-top: 20px;
        margin-right: 20px;
        text-align: right;
        color: #8FC9C0;
        img{
            width: 15px;
            height: 15px;
        }
    }    

    .div1{
        
        // height: 45%;
        // background-color: rgba($color: red, $alpha: 0.6);
    }
    .sp{
        display: inline-block;
        width: 50px;
        margin-bottom: 2%;
    }
    .sp1{
        display: inline-block;
        width: 85px;
         margin-bottom: 2%;
    }

    .sp2{
        // display: inline-block;
        // width: 140px;
         margin-bottom: 10%;
         display: block;
    }
  

    .active{
        // background-color: #295C84;
        border-radius: 3px;
        padding: 3px;
        color: #69E8FF;
    }
    .dangban{
        position: absolute;
        left: 0;
        height: 90%;
        width: 120px;
        border: 1px solid red;
    }

    //new 导航
    .side1{
        position: absolute;
        left: -150px;
        top: -180px;
        width: 450px;
        height: 450px;
        border-radius: 50%;
        z-index: 8;
        // border: 1px solid red;
        background-color: rgba($color: #173145, $alpha: 0.56);
        animation: side1Old 0.3s;
        animation-fill-mode: forwards;
    }
     @keyframes side1Old {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

   .side11{
        animation: side11An 0.3s;
        animation-fill-mode: forwards;
    }
    @keyframes side11An {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }


    .icon1{
        position: absolute;
        top: 45px;
        left: 54px;
        width: 74px;
        height: 74px;
        z-index: 10;
        border-radius: 50%;
        background-image:  linear-gradient(45deg, #009CD1 0%, #2CB5DE 50%, #69DEA9 100%);

        animation: icon11Old 0.3s;
        animation-fill-mode: forwards;
    }
     @keyframes icon11Old {
        0% {
            transform: scale(1.2)
        }
        100% {
            transform: scale(1)
        }
    }

    .iconImage1{
        display: block;
        width: 26.58px;
        height: 25px;
        // background-color: red;
        background: url('../assets/images/icon1.png') center/100% no-repeat;
    }

    // home动画
    .icon11{
        animation: icon11An 0.3s;
        animation-fill-mode: forwards;
    }
    @keyframes icon11An {
        0% {
            transform: scale(1)
        }
        100% {
            transform: scale(1.2)
        }
    }

    //导航2
    .icon20{
        position: absolute;
        top: 24px;
        left: 204px;
        width: 54px;
        height: 54px;
         z-index: 9;
        border-radius: 50%;
        background-image:  linear-gradient(45deg, #E57177 0%, #E8885B 100%);
        transform: translate(-140px,30px)
    }

    .icon2{
        position: absolute;
        top: 35px;
        left: 204px;
        width: 54px;
        height: 54px;
         z-index: 9;
        border-radius: 50%;
        background-image:  linear-gradient(45deg, #E57177 0%, #E8885B 100%);
    }
    .icon21{
        animation: icon22Old 0.3s;
        animation-fill-mode: forwards;
    }

    @keyframes icon22Old {
        0% {
            transform: translate(-140px,10px)
        }
        100% {
             transform: translate(0px,0px)
        }
    }
    .iconImage2{
        display: block;
        width: 20px;
        height: 20px;
        // background-color: red;
        background: url('../assets/images/001.png') center/100% no-repeat;
    }
      // home动画
    .icon22{
        animation: icon22An 0.3s;
        animation-fill-mode: forwards;
    }
    @keyframes icon22An {
        0% {
             transform: translate(0px,0px);
             
        }
        100% {
             transform: translate(-140px,10px)
        }
    }


    //导航2
    .icon3{
        position: absolute;
        top: 132px;
        left: 150px;
        width: 54px;
        height: 54px;
         z-index: 9;
        // background-color: brown;
        border-radius: 50%;
        background-image:  linear-gradient(45deg, #6DDCBA 0%, #5EABE8 50%,#7E8DFC 100%);
    }
     .icon30{
        position: absolute;
               top: 132px;
        left: 150px;
        width: 54px;
        height: 54px;
         z-index: 9;
        // background-color: brown;
        border-radius: 50%;
        background-image:  linear-gradient(45deg, #6DDCBA 0%, #5EABE8 50%,#7E8DFC 100%);
        transform: translate(-80px,-70px)
    }


    .icon31{
        animation: icon33Old 0.5s;
        animation-fill-mode: forwards;
    }
     @keyframes icon33Old {
        0% {
           transform: translate(-80px,-70px)
        }
        100% {
            transform: translate(0,0)
        }
    }

    .iconImage3{
        display: block;
        width: 20px;
        height: 20px;
        // background-color: red;
        background: url('../assets/images/002.png') center/100% no-repeat;
    }
    .icon32{
        animation: icon33An 0.3s;
        animation-fill-mode: forwards;
    }
    @keyframes icon33An {
        0% {
             transform: translate(0px,0px);
             
        }
        100% {
             transform: translate(-80px,-70px)
        }
    }

    



     .icon4{
        position: absolute;
        top: 157px;
        left: 107px;
        width: 54px;
        height: 54px;
         z-index: 9;
        // background-color: brown;
        border-radius: 50%;
        background-image:  linear-gradient(45deg, #E57177 0%, #E8885B 100%);
    }

    .icon40{
        position: absolute;
        top: 157px;
        left: 107px;
        width: 54px;
        height: 54px;
         z-index: 9;
        // background-color: brown;
        border-radius: 50%;
        background-image:  linear-gradient(45deg, #E57177 0%, #E8885B 100%);
         transform: translate(-45px,-100px)
    }

     .icon41{
        animation: icon44Old 0.7s;
        animation-fill-mode: forwards;
    }
     @keyframes icon44Old {
        0% {
           transform: translate(-45px,-100px)
        }
        100% {
            transform: translate(0,0)
        }
    }

    .iconImage4{
        display: block;
        width: 20px;
        height: 20px;
        // background-color: red;
        background: url('../assets/images/003.png') center/100% no-repeat;
    }
    .icon42{
        animation: icon44An 0.3s;
        animation-fill-mode: forwards;
    }
    @keyframes icon44An {
        0% {
             transform: translate(0px,0px);
             
        }
        100% {
             transform: translate(-45px,-100px)
        }
    }

    //图标5动画
     .icon5{
        position: absolute;
        top: 174px;
        left: 24px;
        width: 54px;
        height: 54px;
        z-index: 9;
        // background-color: brown;
        border-radius: 50%;
        background-image:  linear-gradient(45deg, #A573F5 0%, #D2A6F8 100%);
    }
    .icon50{
        position: absolute;
        top: 174px;
        left: 24px;
        width: 54px;
        height: 54px;
        z-index: 9;
        // background-color: brown;
        border-radius: 50%;
        background-image:  linear-gradient(45deg, #A573F5 0%, #D2A6F8 100%);
         transform: translate(40px,-120px)
    }

    .icon51{
        position: absolute;
        top: 174px;
        left: 44px;
        width: 54px;
        height: 54px;
        z-index: 9;
        // background-color: brown;
        border-radius: 50%;
        background-image:  linear-gradient(45deg, #A573F5 0%, #D2A6F8 100%);
        animation: icon55Old 0.8s;
        animation-fill-mode: forwards;
    }
     @keyframes icon55Old {
        0% {
              transform: translate(40px,-120px)
        }
        100% {
            transform: translate(0,0)
        }
    }




    .iconImage5{
        display: block;
        width: 20px;
        height: 20px;
        // background-color: red;
        background: url('../assets/images/004.png') center/100% no-repeat;
    }
    .icon52{
        animation: icon55An 0.3s;
        animation-fill-mode: forwards;
    }
    @keyframes icon55An {
        0% {
             transform: translate(0px,0px);
             
        }
        100% {
             transform: translate(40px,-120px)
        }
    }

    .mainW{
        width: 359px;
    }

    .win{
        opacity: 0;
    }

    .div1 span:hover{
        color: #69E8FF;
    }



</style>