import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    src:'',
    stateShow:'',
  },
  mutations: {
    changeSrc(state, newData){
      state.src = newData;   //判断payload有没有传过来，有就用， 没有就用默认的1
    },
    changeState(state){
      state.stateShow ++;
    },
  },
  actions: {
  },
  modules: {
  }
})
