// 环境：1 深色 2 浅色 3 绿色 4 蓝色 5 黄绿 6 蓝橙 7 棕色 8 灰色 9 单色 10 黑色
// 风格：1 青绿山水 2 中国风   3 写实  4- low-poly   5 科技  6 扁平 7 建筑绘画 8 日式写意  9 太空
// 空间：1 城市建筑 2 2.5D模型 3- 2D/3D混合 4- 3D模型  5 2D模型 6 地形图 7- 卫星图 8 地形图  9- 着色

const filters = {
   
    colorSelect (val) {
      const arr = ['','深色','浅色','绿色','蓝色','黄绿','蓝橙','棕色','灰色','单色','黑色'];
      return arr[val]
    },

    styleSelect(val) {
        const arr = ['','青绿山水','中国风','写实','low-poly','科技','扁平','建筑绘画','日式写意','太空'];
        return arr[val]
    },

    spaceSelect(val) {
        const arr = ['','城市建筑','2.5D模型','2D/3D混合', '3D模型', '2D模型' ,'地形图' ,'卫星图' ,'地形图', '着色']
        return arr[val]
    },

    typeSelect(val) {
        const arr = ['','三维场景','地图场景']
        return arr[val]
    },






    // 地图
    // style风格1影像2科技3标准   texture质感1地理肌理2扁平3线条  space空间1 2D 2 2.5D
    colorSelect1(val) {
        const arr = ['','浅色','深色']
        return arr[val]
    },
  
    styleSelect1(val) {
          const arr = ['','影像','科技','标准']
          return arr[val]
    },
  
    textureSelect1(val) {
          const arr = ['','地理肌理','扁平','线条']
          return arr[val]
    },
    spaceSelect1(val){
        const arr = ['','2D','2.5D']
        return arr[val]
    },




    
    //定义全局 时间过滤器 S
        // Vue.filter('comverTime',function(data,format){
        // return moment(data).format(format);
        // });

   
  }
  
  export default filters