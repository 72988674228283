import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

// const originalPush = VueRouter.prototype.push
//   VueRouter.prototype.push = function push(location) {
//     return originalPush.call(this, location).catch(err => err)
// }


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  
  {
    path: '/template',
    name: 'template',
    component: () => import('../views/template.vue')
  },
  {
    path: '/model',
    name: 'model',
    component: () => import('../views/model.vue')
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('../views/map.vue')
  },
  {
    path: '/picture',
    name: 'picture',
    component: () => import('../views/picture.vue')
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('../views/video.vue')
  },
  {
    path: '/videoShow',
    name: 'videoShow',
    component: () => import('../views/videoShow.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})





export default router
