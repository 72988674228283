<template>
  <div class="home">
    <!-- <side></side> -->
    <div class="box horizontal">
        <div class="logo">
          <img src="../assets/images/logo.png" alt="" class="logo1">
        </div>
    </div>
   
    <div class="box horizontal titleClick">
        <div class="title hand" @click="go">
          <p>
            <span class="titleText fs30 white">开启方案之旅</span>
            <!-- <span class="img" style=""></span> -->
          </p>
        </div>
    </div> 
    <div :class="bg?'change1':'change2'"></div>


  </div>
</template>

<script>

// import side from './side.vue'


export default {
  name: 'Home',
  components: {
    // side,
  },
  data() {
    return {
      bg: true,
    }
  },
  mounted () {
    
  },
  methods: {
    go() {
       this.$router.push('/template');
    }
  },
}
</script>
<style  scoped lang="scss">
  .home{
    width: 100%;
    height: 100%;
  }
  // .logoBox{
  //   width: 100%;
  //   height: 100%;
  // }
  .logo{
    // width:614px;
    // height:145px;
    width: 30%;
    // background: url('../assets/images/logo.png') no-repeat;
    // background-size:100% 100%;
    // margin-top: 15%;
    position: absolute;
    top: 20%;
  }
  .logo1{
     width: 100%;
  }
  .title{
    width:429px;
    height:115px;
    background: url('../assets/images/login.png') no-repeat;
    background-size:100% 100%;
    // margin-top: 10%;
    position: absolute;
    top: 60%;
  }
  .titleText{
    line-height: 115px;
  }
  .title:hover{
    width:429px;
    height:115px;
    background: url('../assets/images/login2.png') no-repeat;
  }
  .img{
    display: inline-block;
    width: 34px;
    height: 24px;
     background: url('../assets/images/right.png') no-repeat;
    background-size:100% 100%;
    margin-left: 60px;
  }
  // .img:hover{
  //   width: 34px;
  //   height: 24px;
  //   background: url('../assets/images/right1.png') no-repeat;
  //   background-size:100% 100%;
  // }
  .titleClick{
    position: absolute;
    z-index: 1;
    width: 100%;
    top: 50%;
  }

  .titleClick:hover{
    span{
          color: #69E8FF;
    }
    .img{
        width: 34px;
        height: 24px;
        background: url('../assets/images/right1.png') no-repeat;
        background-size:100% 100%;
    }
  }

  //背景动画
    .change1{
        width: 100%;
        height: 100%;
        animation: myMou1 2s;
        animation-fill-mode: forwards;
        background-color: black;
    }
    .change2{
        width: 100%;
        height: 100%;
        animation: myMou2 0.5s;
        animation-fill-mode: forwards;
    }
    @keyframes myMou1 {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
    }
    @keyframes myMou2 {
        0% { 
            opacity: 0;
        }
        100% {
           opacity: 1;
        }
    }



  
</style>
