<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->

    <side></side>
    <!-- <mainShow></mainShow> -->
    <!-- <home></home> -->
    <!-- :key="$route.fullPath" -->
    <router-view :key="$route.fullPath" />


  </div>
</template>
<script>

import  side from './components/side';
// import  side from './components/side3';
// import mainShow from './views/main';
import home from './views/Home';

export default {
  components: {
    home,
    side,
    // mainShow
  },
  data() {
    return {
      
    }
  },
  created () {
    
  },
  mounted () {
    
  },
  methods: {
    name() {
      
    }
  },
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  background-color: black;
  background:url("../src/assets/images/bg.jpg");
  width:100%;			//大小设置为100%
  height:100%;			//大小设置为100%
  position:fixed;
  background-size:100% 100%;
  // overflow-y: auto;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
