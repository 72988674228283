import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

import filter from '../src/assets/js/filter'
for (let key in filter){
  Vue.filter(key,filter[key])
}

import Videojs from 'video.js'
import 'video.js/dist/video-js.css'
Vue.prototype.$video = Videojs


import '@/assets/css/common.scss';     //引入自定义样式

import VueBus from 'vue-bus';
Vue.use(VueBus);


import axios from "axios";
Vue.prototype.$axios = axios;


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
